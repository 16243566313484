import lt from 'vuetify/lib/locale/lt'

export default {
  account: 'paskyra',
  accountNotActive: 'paskyra išjungta',
  accounts: 'paskyros',
  active: 'aktyvus',
  add: 'pridėti',
  addAccount: 'pridėti paskyrą',
  addSelected: 'pridėti pasirinktą',
  address: 'adresas',
  additionalLanguage: 'papildoma kalba',
  administration: 'administravimas',
  all: 'visi',
  any: 'bet kuris',
  apiDocs: 'API dokumentacija',
  apiKeys: 'API raktai',
  apply: 'taikyti',
  allWeek: 'visa savaitė',
  back: 'atgal',
  backToLoginPage: 'grįžti į prisijungimo puslapį',
  beverage: 'gėrimas',
  beverages: 'gėrimai',
  brand: 'prekės ženklas',
  brands: 'prekės ženklai',
  businessHours: 'darbo valandos',
  byDays: 'pagal dienas',
  cancel: 'atšaukti',
  cancelDelete: 'atšaukti ištrynimą?',
  canceled: 'atšaukti',
  cantDeleteFirstDeleteRelations:
    'ištrynimas neleidžiamas, pirmiausia ištrinkite susijusius objektus',
  cantDeleteDefaultValue: 'negalima ištrinti numatytosios vertės',
  cause: 'priežastis',
  causeFaultGroup: 'gedimų priežasčių grupė',
  causeFaultGroups: 'gedimų priežasčių grupės',
  causeFaults: 'gedimų priežastys',
  causeGroups: 'priežasčių grupės',
  causes: 'priežastys',
  changePassword: 'keisti slaptažodį',
  cities: 'miestai',
  city: 'miestas',
  client: 'klientas',
  clone: 'klonuoti',
  clientFault: 'kliento klaida',
  clientFaultGroup: 'kliento klaidų grupė',
  clientFaultGroups: 'kliento klaidų grupės',
  clientFaults: 'kliento klaidos',
  clients: 'klientai',
  close: 'uždaryti',
  closed: 'uždaryta',
  code: 'kodas',
  color: 'spalva',
  comment: 'komentaras',
  completedDate: 'užbaigimo data',
  connectionLost: 'ryšys prarastas',
  connectionRestored: 'ryšys atkurtas',
  confirmDelete: 'ar tikrai norite ištrinti šį elementą?',
  confirmOrderCancel:
    'Ar tikrai norite atšaukti darbo užsakymą? Šio veiksmo negalima bus atšaukti',
  confirmPassword: 'patvirtinkite slaptažodį',
  connectServerError: 'serverio prisijungimo klaida',
  contactPerson: 'kontaktinis asmuo',
  contactPersons: 'kontaktiniai asmenys',
  contract: 'sutartis',
  contractor: 'rangovas',
  contracts: 'sutartys',
  controlTechConditions: 'techninių sąlygų kontrolė',
  counter: 'skaitiklis',
  counterFrom: 'skaitiklis nuo',
  counterTo: 'skaitiklis iki',
  countries: 'šalys',
  country: 'šalis',
  create: 'sukurti',
  createdBasedOrder: 'sukurta remiantis užsakymu',
  createdDate: 'sukūrimo data',
  createdOrder: 'sukurtas užsakymas',
  date: 'data',
  dateEnd: 'pabaigos data',
  dateStart: 'pradžios data',
  days: 'dienos',
  daysDot: 'd.',
  decisionMakerName: 'sprendimų priėmėjo vardas',
  decisionMakerPhone: 'sprendimų priėmėjo telefonas',
  default: 'numatytasis',
  defaultLocationBusinessHours: 'numatytosios vietos darbo valandos',
  defaultPageTitle: 'SERVICE DESK',
  defaultReactionTime: 'numatytasis reakcijos laikas',
  defaultSolutionTime: 'numatytasis sprendimo laikas',
  delete: 'ištrinti',
  deleteAccount: 'ištrinti paskyrą',
  deleteUserAccount: 'paskyra bus ištrinta, vartotojas nebegalės prisijungti.',
  description: 'aprašymas',
  details: 'detalės',
  disableAllSorts: 'išjungti visus rūšiavimus',
  distance: 'atstumas',
  distanceFromService: 'atstumas nuo paslaugos centro',
  document: 'dokumentas',
  download: 'parsisiųsti',
  edit: 'redaguoti',
  editDate: 'redaguoti datą',
  editingWillBeAwailableOn: 'redagavimas bus galimas nuo',
  employee: 'darbuotojas',
  eqTypes: 'įrangos tipai',
  equipment: 'įranga',
  equipments: 'įranga',
  error: 'klaida',
  errorCreatingRepeatedOrder: 'klaida kuriant kartotinį užsakymą',
  errorLoadingFromServer: 'klaida įkeliant iš serverio',
  every: 'kiekvienas',
  fault: 'gedimas',
  faultCode: 'gedimo kodas',
  faultGroup: 'gedimų grupė',
  faultGroups: 'gedimų grupės',
  faults: 'gedimai',
  filter: 'filtras',
  filters: 'filtrai',
  firstDeleteAllRelations:
    'pirmiausia reikia ištrinti visus susijusius elementus',
  firstName: 'vardas',
  forgotPassword: 'pamiršote slaptažodį?',
  from: 'nuo',
  fromClients: 'nuo klientų',
  generalInfo: 'bendroji informacija',
  globals: 'globalūs',
  gps: 'gps',
  group: 'grupė',
  groups: 'grupės',
  hide: 'slėpti',
  home: 'pagrindinis',
  hours: 'valandos',
  hoursDot: 'val.',
  identFaultGroups: 'nustatytų gedimų grupės',
  identFaults: 'nustatyti gedimai',
  idents: 'nustatyti',
  instruction: 'instrukcija',
  isRepeat: 'kartojasi',
  key: 'raktas',
  km: 'km',
  language: 'kalba',
  lastName: 'pavardė',
  loading: 'krovimas',
  placement: 'vietovė',
  places: 'vietovės',
  location: 'vietovė',
  locations: 'vietovės',
  login: 'prisijungimas',
  loginIncorect: 'neteisingas prisijungimo vardas arba slaptažodis',
  mailingList: 'pašto sąrašas',
  managerEmail: 'vadovo el. paštas',
  managerName: 'vadovo vardas',
  managerPhone: 'vadovo telefonas',
  maximum: 'maksimumas',
  maxReactionTimeByContract: 'maks. reakcijos laikas pagal sutartį',
  maxSolutionTimeByContract: 'maks. sprendimo laikas pagal sutartį',
  maxTimeOfWorkByContract: 'maks. darbo laikas pagal sutartį',
  maxWorkTime: 'maksimalus darbo laikas',
  middleName: 'antras vardas',
  minimum: 'minimumas',
  minutes: 'minutės',
  minutesDot: 'min.',
  mode: 'režimas',
  model: 'modelis',
  models: 'modeliai',
  name: 'vardas',
  new: 'naujas',
  newPassword: 'naujas slaptažodis',
  nextTry: 'kitas bandymas',
  no: 'ne',
  noData: 'nėra duomenų',
  notActive: 'neaktyvus',
  notCanceled: 'neatšaukti',
  notFound: 'nerasta',
  notFoundDescription: 'išteklius galėjo būti perkeltas arba ištrintas',
  notReceiveCode: 'negavote kodo?',
  notRepeat: 'nekartojamas',
  notification: 'pranešimas',
  notifications: 'pranešimai',
  notificationTimeTaked: 'pranešimo laikas iki paskyrimo',
  notificationTimeReacted: 'pranešimo laikas iki reakcijos',
  notificationTimeResolved: 'pranešimo laikas iki sprendimo',
  now: 'dabar',
  number: 'numeris',
  object: 'objektas',
  objects: 'objektai',
  onlyActive: 'tik aktyvūs',
  onlyByZone: 'tik iš zonų',
  onlyTheir: 'tik savo',
  opened: 'atidaryta',
  order: 'užsakymas',
  orderBusinessHours: 'užsakymų priėmimo laikas',
  orderCancel: 'atšaukti užsakymą',
  orderCanceled: 'atšaukta',
  orderCreatorDetails: 'užsakymo kūrėjo detalės',
  orderCreatorName: 'užsakymo kūrėjo vardas',
  orderCreatorPhone: 'užsakymo kūrėjo telefonas',
  orderCreatorType: 'užsakymo kūrėjo tipas',
  orderEditRules: 'užsakymo redagavimo taisyklės',
  orderStatus: 'užsakymo statusas',
  orderStatuses: 'užsakymų statusai',
  orderStatusHistory: 'užsakymų statusų istorija',
  orderTriggerHint:
    'naujas užsakymas bus sukurtas, kai dabartinis pasieks šią būseną',
  orderType: 'užsakymo tipas',
  orderTypes: 'užsakymų tipai',
  orders: 'užsakymai',
  otherFault: 'kita priežastis',
  otherM: 'kitas',
  others: 'kitų',
  otherW: 'kita',
  owner: 'savininkas',
  owners: 'savininkai',
  part: 'dalis',
  partGroup: 'dalių grupė',
  partGroups: 'dalių grupės',
  parts: 'dalys',
  password: 'slaptažodis',
  period: 'laikotarpis',
  permissions: 'leidimai',
  phone: 'telefonas',
  phones: 'telefonai',
  pressure: 'slėgis',
  range: 'diapazonas',
  reaction: 'reakcija',
  reactionDate: 'darbo pradžios data',
  reactionTime: 'reakcijos laikas',
  reactionTimer: 'reakcijos laikmatis',
  region: 'regionas',
  regions: 'regionai',
  removeSelected: 'pašalinti pasirinktą',
  repeat: 'kartoti',
  repeatedOrder: 'kartotinis užsakymas',
  resend: 'siųsti dar kartą',
  reset: 'atstatyti',
  resetSort: 'atstatyti rūšiavimą',
  retry: 'bandyti dar kartą',
  role: 'vaidmuo',
  roles: 'vaidmenys',
  save: 'išsaugoti',
  savePassword: 'išsaugoti slaptažodį',
  salesByBeverages: 'pardavimai pagal gėrimus',
  salesByWeekDays: 'pardavimai pagal savaitės dienas',
  salesByHours: 'pardavimai pagal valandas',
  search: 'paieška',
  selectAll: 'pasirinkti viską',
  selectDaysOfTheWeek: 'pasirinkite savaitės dienas',
  sendCode: 'siųsti kodą',
  serialNumber: 'serijos numeris',
  serverError: 'serverio klaida',
  serverValidationError: 'validacijos klaida',
  serviceCenter: 'paslaugų centras',
  settings: 'nustatymai',
  scanQrForServiceRequest: 'nuskaitykite šį QR kodą, kad sukurtumėte užsakymą',
  shortName: 'trumpas vardas',
  show: 'rodyti',
  showMore: 'rodyti daugiau',
  showAllColumns: 'rodyti visas stulpelius',
  showLessColumns: 'slėpti stulpelius',
  sla: 'paslaugų lygio susitarimas',
  solution: 'sprendimas',
  solutionDate: 'darbo pabaigos data',
  solutionTime: 'sprendimo laikas',
  solutionTimer: 'sprendimo laikmatis',
  sort: 'rūšiuoti',
  sortDefault: 'numatytasis rūšiavimas',
  staff: 'darbuotojas',
  staffs: 'darbuotojai',
  state: 'būsena',
  status: 'būsena',
  statuses: 'būsenos',
  stopNotifications: 'sustabdyti pranešimus',
  stopReactionTimer: 'sustabdyti reakcijos laikmatį',
  stopSolutionTimer: 'sustabdyti sprendimo laikmatį',
  superAdmin: 'super administratorius',
  tableWidth: 'lentelės plotis',
  telegram: 'telegrama',
  telemetry: 'telemetrija',
  time: 'laikas',
  timeByFaultType: 'laikas pagal gedimo tipą',
  timeDefault: 'numatytasis laikas',
  timeOfWork: 'darbo laikas',
  title: 'pavadinimas',
  to: 'iki',
  toRecoverPassword:
    'norėdami atkurti slaptažodį, įveskite savo telefono numerį ir mes atsiųsime jums SMS su atkūrimo kodu.',
  total: 'iš viso',
  transitionFrom: 'perėjimas iš',
  transitionTo: 'perėjimas į',
  trigger: 'trigeris',
  type: 'tipas',
  types: 'tipai',
  unselectAll: 'atžymėti viską',
  user: 'vartotojas',
  userName: 'vartotojo vardas',
  users: 'vartotojai',
  verifyCode: 'patikrinti kodą',
  video: 'vaizdo įrašas',
  voltage: 'įtampa',
  waitLoadingFromServer: 'prašome palaukti, kol baigsis įkėlimas iš serverio',
  weeks: 'savaitės',
  withoutAccount: 'be paskyros',
  work: 'darbas',
  workTime: 'darbo laikas',
  workGroups: 'darbo grupės',
  works: 'darbai',
  yes: 'taip',
  zone: 'zona',
  zones: 'zonos',
  zonesFilterMode: 'zonų filtro režimas:',
  validation: {
    alpha: 'turi būti tik raidės',
    alreadyTaked: 'jau užimta',
    alreadyStaff: 'kitas vykdytojas jau nurodytas, atnaujinkite puslapį',
    brand: {
      notActive: 'prekės ženklas nėra aktyvus'
    },
    client: {
      notActive: 'klientas nėra aktyvus'
    },
    contract: {
      notActive: 'aktyvus sutartis nerasta',
      notActiveOrExpired:
        'aktyvus sutartis nerasta arba sutarties galiojimo laikas pasibaigė'
    },
    email: 'turi būti galiojantis el. pašto adresas',
    eqType: {
      notActive: 'įrangos tipas nėra aktyvus'
    },
    equipment: {
      notActive: 'įranga nėra aktyvi'
    },
    emails: 'įveskite el. paštą per kablelį',
    integer: 'turi būti sveikasis skaičius',
    ipVsPort: 'turi būti galiojantis ip ir prievadas: xxx.xxx.xxx.xxx:xxxxx',
    isChangedValue: 'pasirinkite naują vertę',
    length: 'turi būti {n} simbolis | turi būti {n} simboliai',
    location: {
      notActive: 'vieta nėra aktyvi'
    },
    maxLength: 'maks. simbolių skaičius: {n}',
    maxValue: 'didžiausia vertė - {n}',
    minLength: 'min. simbolių skaičius: {n}',
    minValue: 'mažiausia vertė - {n}',
    model: {
      notActive: 'modelis nėra aktyvus',
      noContract:
        'nerasta aktyvi sutartis šiam įrangos modeliui arba sutarties terminas pasibaigė'
    },
    numeric: 'turi būti skaičius',
    password: {
      hasLowerCase: 'mažąją raidę',
      hasNumbers: 'skaičių',
      hasSymbol: 'simbolį',
      hasUpperCase: 'didžiąją raidę',
      minLength: 'min. 8 simboliai',
      mustContain: 'slaptažodis turi turėti:'
    },
    passwordMismatch: 'slaptažodžiai nesutampa',
    phone: 'turi būti galiojantis telefonas: +XXXXXXXXXXXX',
    repeatedOrderDate:
      'dėmesio! Kito užsakymo data viršija įrangos priežiūros sutarties galiojimo laiką, užsakymas gali būti nesukurtas!',
    required: 'negali būti tuščias',
    requiredOneOf: 'turi būti pasirinkta bent viena',
    sameAs: 'nesutampa su: {n}',
    url: 'turi būti galiojantis URL'
  },
  weekDays: {
    sunday: 'sekmadienis',
    monday: 'pirmadienis',
    tuesday: 'antradienis',
    wednesday: 'trečiadienis',
    thursday: 'ketvirtadienis',
    friday: 'penktadienis',
    saturday: 'šeštadienis',
    holiday: 'šventinė diena',
    sun: 'sekm',
    mon: 'pirm',
    tue: 'antr',
    wed: 'treč',
    thu: 'ketv',
    fri: 'penkt',
    sat: 'šešt'
  },
  $vuetify: lt
}
